import {useForm} from "react-hook-form";
import {TextInput} from "../inputs/TextInput";
import React from "react";
import axios from "axios";
import CounterInput from "../inputs/CounterInput";
import {WithCaptionAndError} from "../inputs/WithCaptionAndError";
import {Button} from "../inputs/Button";
import DialogLayout from "../layouts/DialogLayout";
import styles from "./AugustCourseForm.module.less";
import stylesLess from "./LottForm.module.less";

const isMoreThenZero = (value) => {
    console.log(value);
    return value > 0;
}

const LottForm = props => {
    const {
        control,
        register,
        formState,
        handleSubmit,
        getValues,
        setValue,
        watch,
        setFocus,
        clearErrors
    } = useForm({});
    // let formData = watch();
    // useEffect(()=>{
    //
    // }, formData);

    const onSubmit = data => {
//        console.log(data);
        // console.log(formState);
        event.preventDefault();
        const post_data = {
            'summa': data['summa'], details: {type: 'lott', ...data}
        }
        console.log(post_data);


        return axios.post(process.env.BACK_PREFIX_URL + "/order/yookassa_rus", post_data,)
            .then((result) => {
                console.log(result)
                if (result.status === 200) {
                    window.open(result.data['confirmation_url'], '_self');
                }
                //         return new Promise((resolve, reject) => {
                //             setTimeout(() => {
                //                 resolve();
                //             }, 3000);
                //         })
            })
            // .then(() => {
            // })
            .catch(e => {
                console.dir(e)
            })
    }

    let summa = watch("summa");
    React.useEffect(() => {
        let price = 500

        let bills = {
            1: 500,
            // 2: 1000,
            5: 2000,
            10: 3500,
            20: 6000,
            100: 25000,
        }
        let bills_amount = (keys, summa) => {
            let key = keys.pop();
            if (key === undefined) {
                // console.log("undefined")
                return 0
            }
            let value = bills[key];
            let counter = (summa / value | 0) * Number(key);
            let reminder = summa % value;
            // console.log(Number(key), counter, reminder);
            if (reminder === 0) {
                return counter;
            }
            return counter + bills_amount(keys, reminder)

        };
        // console.log("before")
        let amount = bills_amount(Object.keys(bills), summa);
        // let amount = summa / price;

        setValue('amount', amount, {shouldValidate: formState.submitCount > 0});

    }, [summa])

    React.useEffect(() => {
        // console.log("set focus");
        setFocus("fio");
    }, [setFocus]);


    // console.dir(formState)
    return (
        <DialogLayout headerText="Добровольное пожертвование">
            <h4>Лотерея в поддержку приоритетных проектов.</h4>
            {/*<p>*/}
            {/*    <div>Цена одного билета 500 ₽</div>*/}
            {/*    <div>5 билетов за 2,000 ₽</div>*/}
            {/*    <div>10 за 3,500 ₽</div>*/}
            {/*    <div>20 за 6,000 ₽</div>*/}
            {/*    <div>100 за 30,000 ₽</div>*/}
            {/*</p>*/}

            <table className={stylesLess.amountTable}>
                <thead>
                <tr>
                    <th>Цена</th>
                    <th>Кол-во билетов</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>500 ₽</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>2,000 ₽</td>
                    <td>5</td>
                </tr>
                <tr>
                    <td>3,500 ₽</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td>6,000 ₽</td>
                    <td>20</td>
                </tr>
                <tr>
                    <td>25,000 ₽</td>
                    <td>100</td>
                </tr>
                </tbody>

            </table>
            <p></p>

            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                <TextInput caption="Фамилия Имя"
                           register={register("fio", {required: "Обязательно нужно заполнить фамилию и имя"})}
                           placeholder="Введите фамилию и имя"
                           error={formState.errors.fio?.type === 'required' && formState.errors.fio.message}/>
                <TextInput caption="E-mail" register={register("email", {required: true})}
                           placeholder="Введите Email"
                           error={formState.errors.email?.type === 'required' && "E-mail тоже обязательный"}/>
                <TextInput caption="Телефон" register={register("phone", {required: true})}
                           placeholder="Введите телефон"
                           error={formState.errors.phone?.type === 'required' && "Телефон тоже нужно ввести"}/>
                <TextInput caption="Город" register={register("city", {required: "Заполните город"})}
                           placeholder="Введите ваш город"
                           error={formState.errors.city?.type === 'required' && formState.errors.city.message}/>
                <WithCaptionAndError caption={"Сумма"}>
                    <CounterInput name={"summa"} setValue={setValue} step={500}
                        // register={register("summa",)}
                                  minValue={500} defaultValue={500} maxValue={5000000} suffix={"₽"}/>
                </WithCaptionAndError>
                <TextInput caption="Количество лотерейных билетов" register={register("amount")} disabled/>
                {/*<WithCaptionAndError caption={"Количество лотерей"} error={formState.errors.qnty?.type === 'required' && formState.errors.qnty.message}>*/}
                {/*    <CounterInput name="qnty"*/}
                {/*                  register={register}*/}
                {/*                  setValue={setValue}*/}
                {/*                  defaultValue={1}*/}
                {/*                  minValue={1}*/}
                {/*    />*/}
                {/*</WithCaptionAndError>*/}
                {/*<div>*/}
                {/*    <Controller*/}
                {/*        control={control}*/}
                {/*        // id="input-example"*/}
                {/*        name="summa"*/}
                {/*        defaultValue={1000}*/}
                {/*        rules={{validate: isMoreThenZero}}*/}
                {/*        render={({field}) => <MoneyInput*/}
                {/*            // disabled*/}
                {/*            // id="input-example"*/}
                {/*            name={field.name}*/}
                {/*            caption="Сумма"*/}
                {/*            onValueChange={field.onChange}*/}
                {/*            onBlur={field.onBlur}*/}
                {/*            value={field.value}*/}

                {/*            // placeholder="Пожалуйста введите сумму пожертвования"*/}
                {/*            // prefix="₽"*/}
                {/*            suffix=" ₽"*/}
                {/*            // defaultValue={field.value}*/}
                {/*            decimalsLimit={2}/>*/}
                {/*        }*/}
                {/*    />*/}
                {/*    <div*/}
                {/*        className="text-input-error">{formState.errors.summa?.type === 'validate' && "Необходимо указать сумму"}</div>*/}
                {/*</div>*/}

                <Button disabled={formState.isSubmitting || Object.keys(formState.errors).length > 0}
                        caption="Оплатить"/>
            </form>
        </DialogLayout>
    );
};
export default LottForm