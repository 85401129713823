import React from 'react';
import {Outlet} from "react-router-dom";
import {Header} from "./Header.tsx";
import styles from './MainLayout.module.less';
import {useAtom} from "jotai/index";
import {themeAtom} from "src/pages/Home/store";


const MainLayout = (props) => {
    // console.log(variables)
    // const [collapsed, setCollapsed] = useState(true);
    // const isMobileScreen = useMediaQuery({query: `(max-width: ${variables.mobileSize})`})
    const [theme, setTheme] = useAtom(themeAtom)


    return (
        <div className={theme}>
            <div className={styles.layoutContainer}>
                <Header/>
                <div className={styles.content}>
                    {props.children}
                    <Outlet/>
                </div>
                {/*</div>*/}
                <div className={styles.flexboxBottom}>© Российская Ассоциация Буддистов Алмазного Пути Традиции Карма
                    Кагью
                </div>
            </div>
        </div>
    );
};

export default MainLayout;
