import React from "react";
import styles from './TextInput.module.less'
import {WithCaptionAndError} from "./WithCaptionAndError";
import classNames from "classnames";

export const TextInput = ({caption, error, register, placeholder, disabled}) => {
    // console.log(register);
    return <WithCaptionAndError caption={caption} error={error}>
        <input className={classNames(styles.textInput, 'test')} type="text" {...register} placeholder={placeholder}
               disabled={disabled}/>
    </WithCaptionAndError>
};