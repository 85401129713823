import React from 'react';
import styles from './DialogLayout.module.less'

const DialogLayout = (props) => {
    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <div className={styles.header}>
                    {/*<Link to="/"><img className="header-icon" src={favicon32} alt="logo"/></Link>*/}
                    <h1 className={styles.headerText}>{props.headerText}</h1>
                </div>
                {props.children}
            </div>
        </div>
    );
};

export default DialogLayout;
