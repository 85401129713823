import axios from "axios";
import urls from "src/data/urls";
import reduxStore from "src/storage/reduxStore";
import {loggedIn} from "src/storage/authSlice";
import {headers_with_csrf} from "src/api/headers";

export const getWhoAmI = async () => axios.post(urls.flask.whoami, {}, headers_with_csrf())
    .then(res => {
        if (res.data.success) {
            // reduxStore.dispatch(loggedIn(res.data));
            // navigate("/v2");
        }
        console.log('whoami', res);
        // return res.data;
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(res.data);
                // return ;
            }, 500);
        })
    })
// .catch(err => {
// })