import React, {useEffect, useState} from 'react';
import styles from './CounterInput.module.less';
import {css} from "@emotion/css";


const CounterInput = (
    {
        name, setValue, register, maxValue = 10000, minValue = 0,
        defaultValue = 0, step = 1,
        suffix = ""
    }) => {
    const [state, setState] = useState(defaultValue);
    // const forceUpdate = useForceUpdate();

    useEffect(() => {
        // register(name);
        setValue(name, state);
        // console.log(state);
    }, [state]);

    const onChange = (rawValue) => {
        let numberValue = Number(rawValue);
        // console.log("raw=", numberValue)
        numberValue = numberValue < maxValue ? numberValue : maxValue;
        numberValue = numberValue > minValue ? numberValue : minValue;
        //
        if (numberValue !== state) {
            setValue(name, numberValue);
            setState(numberValue);
        }
    };
    const onBlur = (e) => {
        let numberValue = Number(e.target.value);
        numberValue = numberValue - numberValue % step;
        if (numberValue !== state) {
            setValue(name, numberValue);
            setState(numberValue);
        }

    };

    const onInputChanged = (e) => {
        // console.log('onInputChanged', e)
        let value = e.target.value;
        onChange(value);
    }
    const onScroll = (e) => {
        // console.log("scroll",e)
        // onChange(e.target.value * step);
    }

    return (<div className={styles.container}>
            <div onClick={() => onChange(String(state === 0 || isNaN(state) ? 0 : state - step))}>
                -
            </div>
            {/*<input type="number" inputMode="numeric" value={String(state)} css={{width: String(state).length  + "ch"}} onChange={onInputChanged}/>*/}
            <input type="number" inputMode="numeric" value={String(state)} step={step}
                   className={css({width: String(state).length + 2 + "ch"})} onChange={onInputChanged} onWheel={onScroll}
                   onBlur={onBlur}/>
            {suffix}
            {/*<ContentEditable html={String(state)} onChange={onInputChanged} onKeyDown={onKeyDown}/>*/}
            <div onClick={() => onChange(String(state + step))}>
                +
            </div>
        </div>

    );
};

export default CounterInput;
