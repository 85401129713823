const flask_url = "/back";

const urls = {
    flask: {
        projects: flask_url + "/api/projects",
        current_user: flask_url + "/api/users/current",
        whoami: flask_url + "/api/whoami",
        uniteller: {pay: flask_url + '/uniteller/order/pay'},
        yookassa_notifies: flask_url + "/api/yookassa/notifiers",
        lot_orders: flask_url + "/api/lot/orders",
        postcard_orders: flask_url + "/api/postcard/orders",
        login: flask_url + "/jwt/login",
        logout: flask_url + "/jwt/logout",
        // login_with_cookies: flask_url + "/jwt/login_with_cookies",
        protected: flask_url + "/jwt/protected",
    },
    front2: {
        baseRoot: '/v2',
        lot: '/lot',
        postcard: '/postcard',
        wt: '/wt',
        front: '/front',
    }
};
// export default urls;
module.exports = urls;
// export flask;