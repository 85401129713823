import Cookies from "js-cookie";
import {AxiosRequestConfig} from "axios";

const defaultDelay = process.env.NODE_ENV === 'production' ? 0 : 500;
export const with_delay = process.env.NODE_ENV === 'production' ? (data: any, delay: number = defaultDelay) => data :
    (data: any, delay: number = defaultDelay) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            try {
                // console.log(data)
                resolve(data)
                // console.log(data)
            } catch (error) {
                // console.log(data)
                reject(error)
                // console.log(data)
            }
        }, delay)
    })
}

// console.log(process.env.NODE_ENV)


export const csrf_token_header = () => {
    return {'X-CSRF-TOKEN': Cookies.get("csrf_access_token"),}
}
export const headers_with_csrf = (): AxiosRequestConfig<{}> => {
    return {headers: {...csrf_token_header()}}
}