import React from "react";
import styles from "./WithCaptionAndError.module.less"

export const WithCaptionAndError = props => {
    return (
        <div className={styles.container}>
            <div className={styles.caption}>{props.caption}</div>
            <div className={styles.children}>{props.children}</div>
            {props.error && <div className={styles.error}>{props.error}</div>}
        </div>
    );
};