import {configureStore} from "@reduxjs/toolkit";
import projectsReducer from "src/bootstrap3/app/projectsSlice";
import authReducer from "./authSlice";


export default configureStore({
    reducer: {
        // projects: projectsReducer,
        auth: authReducer,
    },
})