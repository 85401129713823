import {createSlice} from '@reduxjs/toolkit'

const initialState = [
    // {priority: null, id: "1", name: "БЦ Москва"},
    // {priority: null, id: "2", name: "Great Gompa (Санкт-Петербург)"},
    // {priority: null, id: "2", name: "Great Gompa (Санкт-Петербург)"},
    // {priority: null, id: "2", name: "Great Gompa (Санкт-Петербург)"},
]

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        projectsLoaded(state, action) {
            // console.log(state, action)
            return action.payload;
        },
    },
})

export const {projectsLoaded} = projectsSlice.actions

export default projectsSlice.reducer