import {createSlice} from '@reduxjs/toolkit'

const authSlice = createSlice({
    name: 'auth',
    initialState: {isAuth: false},
    reducers: {
        loggedIn(state, action) {
            return {
                user: action.payload.user,
                isAuth: true
            }
        },
    },
})

export const selectAuth = state => state.auth;
export const {loggedIn} = authSlice.actions

export default authSlice.reducer